import { createUseStyles } from "react-jss";

export const TextInputStyled = createUseStyles((theme: any) => ({
    input: {
        position: "relative",
        marginTop: 5,
        width: "100%",
        "& input": {
            width: "calc(100% - 10px)",
            border: "none",
            borderRadius: theme.borders.default.radius,
            backgroundColor: theme.colors.palette.white,
            color: theme.colors.form.input.filled,
            fontSize: theme.fonts.sizes.base,
            padding: "9px 5px 7px 5px",
            display: "block",
            "&:valid, &:focus-visible": {
                outline: "none",
                backgroundColor: theme.colors.palette.white,
            },
            "&:disabled": {
                fontWeight: "200",
            },
        },
        " & label": {
            fontFamily: theme.fonts.families.site,
            fontSize: theme.fonts.sizes.xxs,
            fontWeight: "bold",
            paddingLeft: 5,
            top: -21,
            zIndex: 99,
        },
        " & input:focus + label": {},
        " & .filled": {},
    },
    withCallOut: { minHeight: 36 },
}));
