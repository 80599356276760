import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IConfigFilterRequest } from "../../../../../../../models/requests/IConfigFilterRequest";
import { useAppSelector } from "../../../../../../../redux/hooks";
import { RequestFilterPivotStyle } from "../filterStyle.jss";
import { TextInput } from "../../../../../../common/inputs/textInput";
import { compileNameOfProperty } from "../../../../../../../utils";
import { IRequestFilter } from "../../../../../../../models";

interface IProps {
    onInputChange: (event: any, name: string) => void;
    schema: IConfigFilterRequest;
    onEnterKey: () => void;
}

export const RequestOthersPivotFilter: React.FC<IProps> = ({ onInputChange, onEnterKey, schema }) => {
    const theme = useTheme();
    const styles = RequestFilterPivotStyle({ theme });
    const { filter } = useAppSelector((state) => state.requestListFilter);
    const { t } = useTranslation("common", { keyPrefix: "REQUEST_FILTER.FILTER" });

    return (
        <div className={`${styles.containerPivot}`}>
            {schema.certificateNumberWithTransportUnitNumber && (
                <div className={styles.filterItem}>
                    <TextInput
                        value={filter.certificateNumberWithTransportUnitNumber}
                        propertyName={compileNameOfProperty<IRequestFilter>("certificateNumberWithTransportUnitNumber")}
                        label={t("CERTIFICATE_NUMBER_TRANSPORT_UNIT_NUMBER")}
                        onChange={onInputChange}
                        onEnter={onEnterKey}
                        withCallOut={true}
                        calloutMessage={t("INFO.CERTIFICATE_NUMBER_TRANSPORT_UNIT_NUMBER")}

                    />
                </div>
            )}
            {schema.transportUnitNumberWithCertificateNumber && (
                <div className={styles.filterItem}>
                    <TextInput
                        value={filter.transportUnitNumberWithCertificateNumber}
                        propertyName={compileNameOfProperty<IRequestFilter>("transportUnitNumberWithCertificateNumber")}
                        label={t("TRANSPORT_UNIT_NUMBER_CERTIFICATE_NUMBER")}
                        onChange={onInputChange}
                        onEnter={onEnterKey}
                        withCallOut={true}
                        calloutMessage={t("INFO.TRANSPORT_UNIT_NUMBER_CERTIFICATE_NUMBER")}
                    />
                </div>
            )}
        </div>
    );
};
