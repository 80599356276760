import { createUseStyles } from "react-jss";

export const ManagementDockLedDisplayAssignedVehiclesStyle = createUseStyles((theme: any) => ({
    containerAssigned: (props: any) => ({
        display: "flex",
        width: "98%",
        justifyContent: "space-between",
        background: props.newAssigned ? "#424242" : "#111111",
        padding: 5,
        gap: theme.gaps.sizes.base,
    }),
    cell: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },

    doubleCell: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    text: {
        fontSize: 20,
        color: theme.colors.palette.white,
        fontWeight: theme.fonts.dockDisplay.weight,
    },

    header: {
        fontSize: "1vw",
        fontWeight: theme.fonts.weight.bold,
    },

    "@keyframes slideRight": {
        from: { opacity: 0 },
        to: { opacity: 1 },
    },
    animation: {
        animationName: "$slideRight",
        animationDuration: "1s",
        animation: "linear 1s infinite",
    },
    "@media (orientation: portrait)": {
        textIcon: {
            display: "none",
        },
        iconCell: {
            width: "10%",
            flexDirection: "row",
        },
        doubleCell: {
            width: "55%",
        },
    },
}));
