import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IRequestFilter } from "../../../../../../../models";
import { useAppSelector } from "../../../../../../../redux/hooks";
import { compileNameOfProperty } from "../../../../../../../utils";
import { TextInput } from "../../../../../../common/inputs/textInput";
import { RequestFilterPopUpStyle } from "../filterStyle.jss";

interface IProps {
    onInputChange: (event: any, name: string) => void;
    schema: any;
    onEnterKey: () => void;
}

export const RequestOthersPivotFilter: React.FC<IProps> = ({ onInputChange, onEnterKey, schema }) => {
    const theme = useTheme();
    const styles = RequestFilterPopUpStyle({ theme });
    const { filter } = useAppSelector((state) => state.requestListFilter);
    const { t } = useTranslation("common", { keyPrefix: "REQUEST_FILTER.FILTER" });

    return (
        <div className={`${styles.section}`}>
            {schema.certificateNumberWithTransportUnitNumber && (
                <TextInput
                    value={filter.certificateNumberWithTransportUnitNumber}
                    propertyName={compileNameOfProperty<IRequestFilter>("certificateNumberWithTransportUnitNumber")}
                    label={t("CERTIFICATE_NUMBER_TRANSPORT_UNIT_NUMBER")}
                    onChange={onInputChange}
                    onEnter={onEnterKey}
                    withCallOut={true}
                    calloutMessage={t("INFO.CERTIFICATE_NUMBER_TRANSPORT_UNIT_NUMBER")}
                />
            )}
            {schema.transportUnitNumberWithCertificateNumber && (
                <TextInput
                    value={filter.transportUnitNumberWithCertificateNumber}
                    propertyName={compileNameOfProperty<IRequestFilter>("transportUnitNumberWithCertificateNumber")}
                    label={t("TRANSPORT_UNIT_NUMBER_CERTIFICATE_NUMBER")}
                    onChange={onInputChange}
                    onEnter={onEnterKey}
                    withCallOut={true}
                    calloutMessage={t("INFO.TRANSPORT_UNIT_NUMBER_CERTIFICATE_NUMBER")}
                />
            )}
        </div>
    );
};
