import React  from "react";
import { ThemeProvider, useTheme } from "react-jss";
import { useNavigate } from "react-router";
import Logo from "../../../assets/images/logo.png";
import { routeUrls } from "../../../common/constants";
import { romeuTheme } from "../../../styles/romeu";
import { Cookies } from "../../common/cookies/cookiesContent";
import { CookieLink } from "../../common/cookies/cookiesLink";
import { SpinnerFull } from "../../common/spinner/spinnerFull/spinnerFull";
import { EnvironmentStyles } from "./templateWithoutAuth.jss";

interface TemplateProps {
    component: React.ReactNode | React.Component | React.FunctionComponent;
    clearTemplate?: boolean;
    hiddenCookies?: boolean;
}

export const TemplateWithoutAuth = (props: TemplateProps & any) => {
    const [currentTheme, setCurrentTheme] = React.useState(romeuTheme);

    function changeTheme(themeValue: string) {
        if (themeValue === romeuTheme.id) {
            setCurrentTheme(romeuTheme);
        } else {
            setCurrentTheme(romeuTheme);
        }
    }

    return (
        <ThemeProvider theme={currentTheme}>
            <BodyContainer
                header={props.header}
                component={props.component}
                footer={props.footer}
                isVisibleSiteNav={props.isVisibleSiteNav}
                changeTheme={changeTheme}
                clearTemplate={props.clearTemplate}
                props={props}
            />
            <SpinnerFull />
            {!props.hiddenCookies && <Cookies />} 
        </ThemeProvider>
    );
};

export const FooterContainer = () => {
    const theme = useTheme();
    const styles = EnvironmentStyles({ theme });

    return (
        <footer className={styles.footer}>
            <CookieLink />
        </footer>
    );
};

const BodyContainer = (props: any) => {
    const theme = useTheme() as any;

   
    const styles = EnvironmentStyles({ theme,  });
    const navigate = useNavigate();
    const body = props.component ? (
        <>
            <props.component {...props.props} />
        </>
    ) : (
        <p>Template referenced without body component</p>
    );

    const clickLogo = () => {
        navigate(routeUrls.HOME);
    };

    return (
        <>
            <div className={styles.siteContainer}>
                <div className={styles.siteBody}>
                    {!props.clearTemplate ? (
                        <header className={styles.siteHeader}>
                            <img
                                src={Logo}
                                alt="Docks Logistics Spain"
                                onClick={clickLogo}
                            />
                            <div className={styles.siteHeaderBottom}></div>
                        </header>
                    ) : (
                        <></>
                    )}
                    {body}
                </div>
                {!props.clearTemplate && <FooterContainer />}
            </div>
        </>
    );
};
