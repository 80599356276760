import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IGenericPayload } from "../../../../../../models";
import { onApplyFilterAction } from "../../../../../../redux/actions/request/filter/filterRequest";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { updateRequestFilterProperty } from "../../../../../../redux/reducers/request/list/filter";
import { CollapsibleCard } from "../../../../../common/collapsibleCard/collapsibleCard";
import { RequestExpedientInfoFilter } from "./expedient/expedient";
import { RequestMerchandiseFilter } from "./merchandise/merchandise";
import { RequestFilterPopUpStyle } from "./filterStyle.jss";
import { RequestUtiFilter } from "./uti/uti";
import { RequestInspectionFilter } from "./inspection/inspection";
import { IConfigFilterRequest } from "../../../../../../models/requests/IConfigFilterRequest";
import { RequestOthersPivotFilter } from "./others/others";
interface IRequestFilterPopUpProps {
    showInspectionBubble: boolean;
    showExpedientBubble: boolean;
    showMerchandiseBubble: boolean;
    showUtiBubble: boolean;
    showOthersBubble: boolean;
    showInspectionPivot: boolean;
    showExpedientPivot: boolean;
    showMerchandisePivot: boolean;
    showOthersPivot: boolean;
    showUtiPivot: boolean;
    schema?: IConfigFilterRequest;
}
export const RequestFilterPopUp: React.FC<IRequestFilterPopUpProps> = ({
    showExpedientBubble,
    showInspectionBubble,
    showMerchandiseBubble,
    showUtiBubble,
    showOthersBubble,
    schema,
    showExpedientPivot,
    showInspectionPivot,
    showMerchandisePivot,
    showOthersPivot,
    showUtiPivot,
}) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const styles = RequestFilterPopUpStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "REQUEST_FILTER.TITLES" });
    const { profile } = useAppSelector((state) => state.userData);
    const currentOrganization = useAppSelector((store) => store.organization.organization.id);

    const organization = useMemo(() => {
        return profile.organizations.find((item) => item.id === currentOrganization);
    }, [profile.organizations, currentOrganization]);

    const onMultipleNumberKeysChange = useCallback(
        (name: string, numberOptions: number[]) => {
            const payload: IGenericPayload = {
                name,
                value: numberOptions,
            };

            dispatch(updateRequestFilterProperty(payload));
        },
        [dispatch],
    );
    const onMultipleStringKeysChange = useCallback(
        (name: string, stringOptions: string[]) => {
            const payload: IGenericPayload = {
                name,
                value: stringOptions,
            };

            dispatch(updateRequestFilterProperty(payload));
        },
        [dispatch],
    );
    const onNullableBooleanChange = useCallback(
        (name: string, nullablebooleanValue: any) => {
            const payload: IGenericPayload = {
                name,
                value: nullablebooleanValue,
            };

            dispatch(updateRequestFilterProperty(payload));
        },
        [dispatch],
    );
    const onInputChange = useCallback(
        (event: any, name: string) => {
            const payload: IGenericPayload = {
                name,
                value: event.target.value,
            };
            dispatch(updateRequestFilterProperty(payload));
        },
        [dispatch],
    );
    const onInputNumberChange = useCallback(
        (value: number | undefined, name: string) => {
            const payload: IGenericPayload = {
                name,
                value,
            };
            dispatch(updateRequestFilterProperty(payload));
        },
        [dispatch],
    );

    const onApply = useCallback(() => {
        dispatch(onApplyFilterAction());
    }, [dispatch]);

    return (
        <div className={styles.requestFilterContainer}>
            {showUtiPivot && (
                <CollapsibleCard
                    title={t("REQUEST_DATA")}
                    children={
                        <RequestUtiFilter
                            onMultipleNumberKeysChange={onMultipleNumberKeysChange}
                            onNullableBooleanChange={onNullableBooleanChange}
                            onInputChange={onInputChange}
                            onMultipleStringKeysChange={onMultipleStringKeysChange}
                            onInputNumberChange={onInputNumberChange}
                            schema={schema}
                            onEnter={onApply}
                        />
                    }
                    showBubble={showUtiBubble}
                />
            )}
            {showMerchandisePivot && (
                <CollapsibleCard
                    title={t("MERCHANDISE")}
                    children={
                        <RequestMerchandiseFilter
                            onMultipleNumberKeysChange={onMultipleNumberKeysChange}
                            onNullableBooleanChange={onNullableBooleanChange}
                            onInputChange={onInputChange}
                            onEnter={onApply}
                            schema={schema}
                            organization={organization}
                        />
                    }
                    showBubble={showMerchandiseBubble}
                />
            )}
            {showExpedientPivot && (
                <CollapsibleCard
                    title={t("EXPEDIENT_INFO")}
                    children={
                        <RequestExpedientInfoFilter
                            onMultipleNumberKeysChange={onMultipleNumberKeysChange}
                            onNullableBooleanChange={onNullableBooleanChange}
                            onInputChange={onInputChange}
                            onMultipleStringKeysChange={onMultipleStringKeysChange}
                            schema={schema}
                            onEnter={onApply}
                        />
                    }
                    showBubble={showExpedientBubble}
                />
            )}
            {showInspectionPivot && (
                <CollapsibleCard
                    title={t("INSPECTION")}
                    children={
                        <RequestInspectionFilter
                            onMultipleNumberKeysChange={onMultipleNumberKeysChange}
                            onNullableBooleanChange={onNullableBooleanChange}
                            schema={schema}
                        />
                    }
                    showBubble={showInspectionBubble}
                />
            )}

            {showOthersPivot && (
                <CollapsibleCard
                    title={t("OTHERS")}
                    children={
                        <RequestOthersPivotFilter
                            onInputChange={onInputChange}
                            onEnterKey={onApply}
                            schema={schema}
                        />
                    }
                    showBubble={showOthersBubble}
                />
            )}
        </div>
    );
};
