import { IRequestFilter } from "../../../../models";
import { IConfigFilterRequest } from "../../../../models/requests/IConfigFilterRequest";
import { IField } from "../../../../models/requests/IUserRoleView";
import { compileNameOfProperty, isFieldVisible } from "../../../../utils";

export const generateFilterRequestSchema = (fields: IField[]): IConfigFilterRequest => {
    return {
        transportUnitIdVisible: isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("transportUnitId")),
        transportUnitSizeIdVisible: isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("transportUnitSizeId")),
        transportUnitNumberVisible: isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("transportUnitNumber")),
        stateIdVisible: isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("stateId")),
        dockIdVisible: isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("dockId")),
        turnIdVisible: isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("turnId")),
        customerIdVisible: isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("customerId")),
        terminalIdVisible: isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("terminalId")),
        merchandiseTypeIdVisible: isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("merchandiseTypeId")),
        merchandiseOriginIdVisible: isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("merchandiseOriginId")),
        customsStateIdVisible: isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("customsStateId")),
        finalDestinationTypeIdVisible: isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("finalDestinationTypeId")),
        organismIdVisible: isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("organismId")),
        actuationTypeGroupIdVisible: isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("actuationTypeGroupId")),
        actuationTypeIdVisible: isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("actuationTypeId")),
        certificateTypeIdVisible: isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("certificateTypeId")),
        certificateNumberVisible: isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("certificateNumber")),
        summaryNumberVisible: isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("summaryNumber")),
        documentTypeIdVisible: isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("documentTypeId")),
        invoicedVisible: isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("invoiced")),
        valuedVisible: isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("valued")),
        vuaVisible: isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("vua")),
        appVisible: isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("app")),
        parkingAuthorizationVisible: isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("parkingAuthorization")),
        halalCertifiedVisible: isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("halalCertified")),
        dryChargeVisible: isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("dryCharge")),
        fumigatedVisible: isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("fumigated")),
        organicProductVisible: isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("organicProduct")),
        inspectorAssignedVisible: isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("inspectorAssigned")),
        inspectorIdVisible: isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("inspectorId")),
        summaryActiveVisible: isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("summaryActive")),
        sortVisible: isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("sort")),
        appointmentTimeZoneVisible:isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("appointmentTimeZoneId")),
        appointmentAssigned:isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("appointmentAssigned")),
        descriptionVisible:isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("description")),
        humanConsumptionVisible:isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("humanConsumption")),
        transportUnitNumberWithCertificateNumber:isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("transportUnitNumberWithCertificateNumber")),
        certificateNumberWithTransportUnitNumber:isFieldVisible(fields, compileNameOfProperty<IRequestFilter>("certificateNumberWithTransportUnitNumber")),
    };
};
