import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { patchRequestInspectionsAssignInspector } from "../../../../../../../redux/actions/request/list/requestList";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { resetAssignInspectorFormData } from "../../../../../../../redux/reducers/request/list/form/assignInspector";
import { PopUpForm } from "../../../../../../common/popup/popUpForm/popUpForm";
import { RequestListExpedientsAddInspectorContainer } from "../form/form";
import { setPersistScrollOnDetailsList } from "../../../../../../../redux/reducers/webConfig/globalConfig";
import { setStopAutoReload } from "../../../../../../../redux/reducers/request/list/requestlist";
interface IRequestListExpedientsInspectorsFormControlerProps {
    showAddInspectorToIncidence: boolean;
    setShowAddInspectorToIncidence: (value: any) => any;
    inspectorId: string;
}

export const RequestListExpedientsInspectorsFormControler: React.FC<IRequestListExpedientsInspectorsFormControlerProps> = ({
    setShowAddInspectorToIncidence,
    showAddInspectorToIncidence,
    inspectorId,
}) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "LIST.EXPEDIENTS_DESCRIPTIONS.ASSIGN_INSPECTOR" });
    const { form } = useAppSelector((store) => store.assignInspectorForm);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const onDimissed = () => {
        dispatch(resetAssignInspectorFormData());
        setIsLoading(false);
        dispatch(setStopAutoReload(false));
    };
    const onCancelAssignInspectorForm = () => {
        setShowAddInspectorToIncidence(false);
        dispatch(setStopAutoReload(false));
    };

    const onSaveAssignInspectorForm = async () => {
        dispatch(setStopAutoReload(false));
        if (form.inspectorId === inspectorId) {
            return onCancelAssignInspectorForm();
        }
        setIsLoading(true);
        dispatch(patchRequestInspectionsAssignInspector(form.requestId, form.inspectionId, form.inspectorId)).then((response: any) => {
            response && onCancelAssignInspectorForm();
        });
        dispatch(setPersistScrollOnDetailsList(true));
    };

    return (
        <PopUpForm
            content={<RequestListExpedientsAddInspectorContainer />}
            title={t("TITLE")}
            isVisible={showAddInspectorToIncidence}
            height={"150px"}
            width={"30vw"}
            onCancel={onCancelAssignInspectorForm}
            onSave={onSaveAssignInspectorForm}
            onDimissed={onDimissed}
            isDisabledButton={!form.expedientNumber || !form.inspectorId}
            isLoading={isLoading}
        />
    );
};
