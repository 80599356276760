import React, { useMemo } from "react";
import { FieldError } from "react-hook-form";
import { DefaultValues } from "../../../common/enum/DefaultValues";
import i18n from "../../../services/i18n";
import { Input } from "../inputs/input";
import { Label } from "../labels/label";
import { Textarea } from "../textarea/textarea";
import { FieldsStyle } from "./fields.jss";

interface IFieldsProps {
    name: string;
    type: string;
    label?: string;
    value: string;
    error?: FieldError | any;
    disabled?: boolean;
    isRequired?: boolean;
    maxLength?: number;
    onChange: (e: any) => any;
    onBlur?: (e: any) => any;
    readonly?: boolean;
    autocomplete?:string;
    inputClassName?: string;
    minValue?: string;
    maxValue?: string;
    filledLabel?: boolean;
}

export const FormField = React.forwardRef<HTMLInputElement, IFieldsProps>(
    ({ autocomplete, onChange, onBlur, name, label, type, value, filledLabel, error, disabled, isRequired, maxLength, readonly, inputClassName, minValue, maxValue }, ref) => {
        const inputStyle = FieldsStyle();
        const valueInput = !value && disabled ? DefaultValues.EMPTY : value;

        const filled: string | number = !!valueInput && valueInput[0]?.length > 0 ? "filled" : "";
        const errorMessage = useMemo(() => {
           
            return error ? <div className={inputStyle.error}>{i18n.t(error.message ?? "")}</div> : null;
        }, [error]);
      

        return (
                <div className={inputStyle.formGroup}>
                    <Input
                        name={name}
                        value={valueInput}
                        type={type}
                        maxLength={maxLength}
                        onChange={onChange}
                        onBlur={onBlur}
                        ref={ref}
                        disabled={disabled}
                        readonly={readonly}
                        autocomplete={autocomplete}
                        className={inputClassName}
                        min={minValue}
                        max={maxValue}
                    />
                    {label && (
                        <Label
                            title={label}
                            className={filledLabel ? "filled" : filled}
                            isRequired={isRequired}
                        />
                    )}
                    {errorMessage}
                </div>
        );
    },
);

interface IFieldsTextareaProps {
    name: string;
    label?: string;
    value: string;
    rows?: number;
    error?: FieldError;
    isRequired?: boolean;
    disabled?: boolean;
    onChange: (e: any) => void;
}

export const FormFieldTextarea = React.forwardRef<HTMLTextAreaElement, IFieldsTextareaProps>(
    ({ onChange, name, label, value, rows, error, disabled, isRequired }, ref) => {
        const inputStyle = FieldsStyle();
        const valueInput = !value && disabled ? DefaultValues.EMPTY : value;
        const filled: string = !!valueInput && valueInput[0]?.length > 0 ? "filled" : "";

        return (
            <>
                <div className={inputStyle.formGroupTextArea}>
                    <Textarea
                        rows={rows}
                        name={name}
                        value={value}
                        disabled={disabled}
                        onChange={onChange}
                        ref={ref}
                    />

                    {label && (
                        <Label
                            title={label}
                            className={filled}
                            isRequired={isRequired}
                        />
                    )}

                    {error && <div className={inputStyle.error}>{i18n.t(error.message ?? "")}</div>}
                </div>
            </>
        );
    },
);
