import { useId } from "@fluentui/react-hooks";
import { ShowCallOutComponent } from "../radioButton/controlledRadioButtonGroup/controlledRadioButtonGroup";
import { RequiredSpan } from "../required/requiredSpan";
import { LabelStyle } from "./labelStyle.jss";

interface ILabelProps {
    title: string;
    className?: string;
    isRequired?: boolean;
}

interface ILabelPropsWithCallout {
    isCalloutVisible: boolean;
    calloutMessage: string;
    setCalloutVisibility:  (value: any) => any;
}

export const Label = (props: ILabelProps) => {
    const labelStyle = LabelStyle();

    return (
        <>
            <label className={`${props.className} ${labelStyle.label}`}>
                {props.title} {props.isRequired && <RequiredSpan />}
            </label>
        </>
    );
};

export const LabelWithInfo = (props: ILabelProps & ILabelPropsWithCallout) => {
    const iconId = useId("callout-iconButton");
    return (
        <>
                <ShowCallOutComponent
                    calloutLabel={props.title}
                    required={props.isRequired}
                    iconId={iconId}
                    setCalloutVisibility={props.setCalloutVisibility}
                    isCalloutVisible={props.isCalloutVisible}
                    calloutMessage={props.calloutMessage}
                    customStyle={props.className}
                    isForField={true}
                />
        </>
    );
};
