import { createUseStyles } from "react-jss";

export const ControlledRadioButtonGroupStyled = createUseStyles((theme: any) => ({
    radioButtonContainer: {
        marginTop:-11,
        "& .ms-Label": {
            padding: 0,
            color: theme.colors.form.input.default,
            fontSize: "0.75rem",
            fontWeight: "100",
            fontFamily: theme.fonts.families.site,
            marginBottom:-5
        },
        "& .ms-Label::after": {
            color: theme.colors.palette.red,
        },
        "& .ms-ChoiceFieldGroup-flexContainer": {
            display: "flex",
            columnGap: 12,
        },
        "& .ms-ChoiceFieldGroup": {
            display: "flex",
            flexDirection: "column",
        },

        "& .is-checked": {
            "&:after": {
                borderColor: theme.colors.radioButtons.color,
            },
            "&:before": {
                borderColor: theme.colors.radioButtons.borderColor,
            },
        },
    },
}));

export const ShowCallOutComponentStyle = createUseStyles((theme: any) => ({
    radioButtonCalloutContainer: {
        display: "flex",
        flexDirection: "row",
        fontSize: theme.fonts.sizes.xs,
        alignItems: "start",
        columnGap: 8,

        "& label": {
            color: theme.colors.form.input.default,
            fontSize: theme.fonts.sizes.xs,
            fontWeight: 100,
            fontFamily: theme.fonts.families.siteImportant,
            marginBottom:-5

        },
    },
    labelForField: {
        marginTop: -39,
    },
    radioButtonCalloutMessage: {
        padding: 16,
        lineHeight: "1.6",
        whiteSpace: "pre-wrap",
        fontSize: theme.fonts.sizes.xs,
        fontWeight: 100,
        fontFamily: theme.fonts.families.siteImportant,
    },

    radioButtonCalloutLabelContainer: {
        display: "flex",
        whiteSpace: "pre",
        columnGap: 4,
    },
    selectorRequired: {
        color: theme.colors.palette.red,
    },
    iconContainer: {
        display: "flex",
        "& i": {
            color: theme.colors.buttons.primary.background,
            cursor: "help",
        },
    },
}));
