import { useTranslation } from "react-i18next";
import { IActuationModal } from "../../../../../../models/common";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { resetActuationFormDataState } from "../../../../../../redux/reducers/request/edit/form/actuation";
import { PopUpForm } from "../../../../../common/popup/popUpForm/popUpForm";
import { RequiredFieldsMessage } from "../../../../../common/requiredMessage/requiredMessage";
import { RequestListActuationContainer } from "../../../../list/actuation/container";
import { setStopAutoReload } from "../../../../../../redux/reducers/request/list/requestlist";

interface IRequestListActuationListControllerProps {
    infoActuation: IActuationModal;
    setInfoActuation: (value: any) => any;
    requestId: number;
    organismIdExpedient?: number;
}

export const RequestListActuationListController: React.FC<IRequestListActuationListControllerProps> = ({
    infoActuation,
    setInfoActuation,
    requestId,
    organismIdExpedient
}) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "LIST.EXPEDIENTS_DESCRIPTIONS" });
    const { loadingList } = useAppSelector((store) => store.actuationForm);

    const onCancelFormActuationForm = () => {
        dispatch(resetActuationFormDataState());
        setInfoActuation({ visible: false });
        dispatch(setStopAutoReload(false));

    };

    return (
        <PopUpForm
            content={
                <RequestListActuationContainer
                    inspectionId={infoActuation.inspectionId ?? 0}
                    requestId={requestId}
                    organismIdExpedient={organismIdExpedient}
                />
            }
            title={t("ACTUATIONS")}
            isVisible={infoActuation.visible}
            onCancel={onCancelFormActuationForm}
            onSave={() => null}
            height={loadingList ? "30vh" : "auto"}
            width={"55vw"}
            isBlocking={false}
            isLoading={loadingList}
            onlyHidden={true}
            leftButtonContent={<RequiredFieldsMessage />}
        />
    );
};
