import { createUseStyles } from "react-jss";

export const RequestFilterPivotStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: theme.gaps.sizes.base,
        paddingLeft: "18px",
        width: "100%",
        height: "100%",
    },
    pivot: {
        paddingBottom: theme.paddings.sizes.base,
        width: 850,
        height: "100%",

        "& .ms-FocusZone": {
            height: 43,
            borderBottom: `1px solid ${theme.colors.navLinks.primary.color}`,
        },
        "& .ms-Pivot": {
            "&  .ms-Button": {
                height: 42,
                minWidth: 125,
                fontWeight: "bold",
                color: theme.colors.palette.greyPurple,
                "& .ms-Button-flexContainer": {
                    height: "auto",
                },
            },
            "&  .ms-Button:hover": {
                color: theme.colors.palette.purple,
                backgroundColor: "transparent",
            },
            "&  .ms-Button:active": {
                color: theme.colors.palette.purple,
            },
            "& .is-selected": {
                color: theme.colors.palette.purple,
            },
            "& .is-selected::before": {
                color: theme.colors.palette.purple,
                backgroundColor: theme.colors.palette.purple,
            },
            "& .is-selected::after": {
                color: theme.colors.palette.purple,
            },
        },
    },
    buttons: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        justifyContent: "flex-start",
        height: "100%",
        paddingBottom: 12,
        "& > div": {
            display: "flex",
            flexDirection: "column",
            gap: theme.gaps.sizes.xs,
            "& button": {
                width: 50,
                "& i": {
                    fontSize: "15px",
                },
            },
        },
    },
    containerPivot: {
        display: "flex",
        flexFlow: "row wrap",
        columnGap: theme.gaps.sizes.sm,
        paddingTop: theme.paddings.sizes.xs,
        width: "100%",
    },
    filterItem: (props: any) => ({
        paddingTop: 5,
        width: props.hasFiveColumns ? 161 : 205,
    }),
    filterItem2: (props: any) => ({
        paddingTop: 5,
        width: props.hasFiveColumns ? 332 : 420,
    }),
}));
