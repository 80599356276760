import DateFnsUtils from "@date-io/dayjs";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { FormField } from "../fields/fields";
import { TimePickerComponentStyle } from "./timePicker.jss";
import { useEffect, useState } from "react";

interface ITimePickerComponentProps {
    onChange: (date: MaterialUiPickersDate) => void;
    value: string | null | Date;
    label: string;
    hasError?: boolean;
    required?: boolean;
    disabled?: boolean;
    updateDate?: boolean;
    minDate?: any;
    picker?: boolean;
    isCreate?: boolean;
}

export const TimePickerComponent = (props: ITimePickerComponentProps) => {
    const theme = useTheme();
    const style = TimePickerComponentStyle({ theme, hasError: props.hasError });
    const { t } = useTranslation("common", { keyPrefix: "TIME_PICKER" });
    const date =  props.isCreate ? new Date()  :!isNaN(new Date(props.value!).valueOf()) ? props.value : null;
    const [time, setTime] = useState<string>("");
    const [minTime, setMinDate] = useState<string | undefined>(undefined);
    
    useEffect(() => {
        if (props.minDate) {
            const date = new Date(props.minDate);
            if (!isNaN(date.valueOf())) {
                setMinDate(dayjs(date).format("HH:mm"));
            }
        }

        if (props.value) {
            const date = new Date(props.value);
            if (!isNaN(date.valueOf())) {
                setTime(dayjs(date).format("HH:mm"));
            }
        } else {
            setTime("");
        }
    }, [props.value, props.minDate]);

    const onChange = (date: MaterialUiPickersDate) => {
        if (date?.isValid()) {
            props.onChange(dayjs(date).set("seconds", 0));
        }
    };

    const onChangeTime = (e: any) => {
        const time = e.target.value;
        const [hours, minutes] = time.split(":");
        if (hours !== undefined && minutes !== undefined) {
            let newDate;
            if(props.isCreate) {
                newDate = dayjs(new Date()).set("hours", parseInt(hours)).set("minutes", parseInt(minutes));
            } else {
                newDate = dayjs(props.value).set("hours", parseInt(hours)).set("minutes", parseInt(minutes));
            }
            if (newDate.isValid()) {
                props.onChange(newDate);
            } else {
                console.error("Invalid date created from time input");
            }
        } else {
            console.error("Invalid time input");
        }
    };

    return !props.picker ? (
        <FormField
            type="time"
            label={props.label}
            filledLabel={true}
            value={time}
            isRequired={props.required}
            disabled={props.isCreate ? props.disabled : props.disabled || date === null}
            name="time"
            onChange={(e) => {
                setTime(e.target.value);
            }}
            onBlur={(e) => {
                setTime(e.target.value);
                onChangeTime(e);
            }}
            autocomplete="off"
            inputClassName={style.inputTime}
        />
    ) : (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <TimePicker
                autoOk
                cancelLabel={t("CANCEL")}
                okLabel={t("SAVE")}
                value={date}
                onChange={onChange}
                className={style.label}
                DialogProps={{ className: style.timePicker }}
                label={props.label}
                ampm={false}
                InputLabelProps={{ className: style.inputLabel }}
                contentEditable={true}
                required={props.required}
                disabled={props.disabled}
            />
        </MuiPickersUtilsProvider>
    );
};
