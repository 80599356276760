import { createUseStyles } from "react-jss";

export const ManagementDockLedDisplayWaitingVehiclesStyle = createUseStyles((theme: any) => ({
    containerWaiting: (props: { itemCount: number }) => ({
        display: "grid",
        gridTemplateColumns: props.itemCount > 1 ? "repeat(2, 1fr)" : "repeat(1, 1fr)",
        gap: 13,
        gridAutoFlow: "dense",
        gridAutoRows: "10px", 
    }),
    gridContainer: {
        backgroundColor: "#424242",
        borderRadius: 0,
        padding: 5,
        order: 0,
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.5)",
        WebkitBoxShadow: "0px 0px 5px 0px rgba(0,0,0,0.5)",
        moxBoxShadow: "0px 0px 5px 0px rgba(0,0,0,0.5)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    containerTitle: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 5,
        width: "100%",
        marginBottom: 6,
    },
    titleWaiting: {
        display: "flex",
        flexDirection: "column",
        fontSize: 14,
        letterSpacing: 1,
        gap: 5,
        textTransform: "uppercase",
        width: "100%",
        textAlign: "center",
        color: theme.colors.palette.white,
        fontWeight: 400,
    },
    itemList: {
        width: "100%",
        textAlign: "center",
        fontSize: 14,
        letterSpacing: 1,
        fontWeight: 600,
        color: theme.colors.palette.white,
        "& > div": {
            padding: "3px 0",
        },
    },
    "@media (orientation: portrait)": {
        gridContainer: {
            padding: "15px 5px"          ,
        },
        
    },
}));
